
.react-date-picker {
    display: block;
    position: relative;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-date-picker__wrapper {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 36px;
    background-color: #ffffff;
}

.react-date-picker__inputGroup {
    min-width: 100px;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
}

.react-date-picker__calendar {
    width: 450px;
    max-width: 100vw;
    z-index: 1;
}

.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: 0;
    padding-left: 0;
}

.react-date-picker__inputGroup__divider {
    padding: 0;
    white-space: pre;
}
